.wapper-btns {
  max-width: 14rem;
  padding: 1rem;
}

[class*="btn"] {
  cursor: pointer;
}

.contact-us-new *{
  box-sizing: border-box;
}
.tooltip {
  position: absolute;
  background-color: white;
  padding: 1rem;
  box-shadow: var(--shadowColor);
  border-radius: 0.5rem;
  max-width: 12rem;
  z-index: 22;
  left: 50%;
  translate: -50% -101%;
  border: 1px solid var(--darkBlue);
}

.tooltip::after {
  content: "";
  position: absolute;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.75rem solid var(--darkBlue);
  top: 100%;
  left: 50%;
  translate: -50% 0;


}

.btn {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 0.6rem;
  margin: 0.5rem 0;
  color: #282c34;
  width: 100%;
  border: 0;
}

.error {
  color: red;
  margin: 0;
  font-size: 0.8rem;
}

.fines {
  padding: 2rem;
  border-radius: 1rem;
}

.btn-standard {
  background-color: rgb(0, 69, 138);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  border-radius: 16rem;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin: 0.5rem;
}

.fine-box {
  overflow: hidden;
  box-shadow: var(--shadowColor);
  margin: 1rem 0;
  border-radius: 1rem;
  flex: 1 1 25rem;
  max-width: 36rem;
}

[disable-click="true"] {
  pointer-events: none;
}

.fine-box .name {
  padding: 0.5rem;
  flex: 1;
  text-align: center;
}

.choose-type {
  padding: 0.5rem;
  cursor: pointer;
}

.choose-type.active {
  border-bottom: 3px solid var(--koromiko);
}

.btn-standard.outline {
  background-color: unset;
  border: 1px solid var(--darkBlue);
  color: var(--darkBlue)
}

.btn.Link {}

.btn.Link::after {
  content: ">";
  margin-inline-start: 0.3rem;
}

.box-input {
  width: 16rem;
  padding: 0.5rem 0;
}

input.input {
  padding: 0.6rem;
  display: flex;
  border: 0.1rem solid rgb(205, 205, 205);
  border-radius: 2rem;

  width: 100%;
  justify-content: center;
}

.title {
  display: inline-block;
  font-family: "OpenSans-Bold", "HelveticaNeue-Bold";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--koromiko);
  color: var(--darkBlue);
  border-bottom-width: .2rem;
  padding: .5rem 0;
}

.title-color {
  color: var(--darkBlue);
  font-family: "OpenSans-Bold", "HelveticaNeue-Bold";
  font-size: 1.6rem;
  margin: 0;
}

.subtitle {
  color: var(--gray);
}

.text-color {
  font-family: "OpenSans-Bold", "HelveticaNeue-Bold";
  font-size: 1.375rem;
  color: var(--darkBlue);
}


.text {
  font-family: "OpenSans-Light";
  font-size: 1.275rem;
  line-height: 1.75rem;
}

.text-regular {
  font-family: "OpenSans-Regular";
  font-size: 1.275rem;
  line-height: 1.75rem;
  color: var(--darkBlue);
}

.text-regular {
  font-family: "OpenSans-Regular";
  font-size: 1.275rem;
  line-height: 1.75rem;
  color: var(--darkBlue);
}

.text-link {
  font-family: "OpenSans-Regular";
  font-size: 1.275rem;
  color: var(--cyanBold);
}

.font-color {
  color: var(--darkBlue)
}

.btn-full-width {
  font-family: "OpenSans-Bold", "HelveticaNeue-Bold";
  background: var(--darkBlue);
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 12px;
  cursor: pointer;
}

.box-career {
  flex: 1 1 25rem;
  max-width: 36rem;
  box-shadow: var(--shadowColor);
  border-radius: 15px;
  overflow: hidden;
}

.padding-wapper {
  padding: 15px;
}

.modal {
  display: flex;
  position: fixed;
  background: #000000cc;
  z-index: 999999999;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 2rem;
}

.modal-inner.smaller {
  padding: 1rem;
  gap: 2rem;
  animation-name: grow 0.5s;
  animation-duration: 0.5s;
  animation-delay: 5s;
  max-width: 35rem;
  width: 84%;
  height: 15rem;
  max-height: 100%;
  justify-content: center;
  align-items: center;

}

.title-modal {
  font-size: 1.5rem;
}

.dot-animation::after {
  width: 36px;
  height: 36px;
  content: "";
  background-color: rgb(0, 69, 138);
  z-index: -1;
  position: absolute;
  border-radius: 50%;
  left: -1000px;
  right: -1000px;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.4;
  /* animation: mymove 1.2s infinite; */
  animation: dotGrow 1.2s infinite;
  /* animation: dotGrow 1s infinite; */
}

@keyframes dotGrow {
  0% {
    width: 15px;
    height: 15px;
    opacity: 0.8;
  }

  100% {
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}

@keyframes grow {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}

@keyframes shrink {
  from {
    scale: 1;
  }

  to {
    scale: 0;
  }
}

@keyframes growHeight {
  from {
    max-height: 0px;
  }

  to {
    max-height: 500px;
  }
}

.modal-wrapper {
  display: flex;
  position: relative;
  max-width: 76rem;
  width: 100%;
  max-height: 90vh;
  animation-name: grow;
  animation-duration: 0.5s;

}

.modal-inner {
  display: flex;
  overflow: hidden;
  width: 100%;
  /* max-height: 100%; */
  min-width: 20rem;
  background: white;
  border-radius: 0.625rem;
  position: relative;
  line-height: 1.6rem;
}


.modal-content {
  overflow: auto;
  width: 100%;
  /* height: 100%; */
  padding: 1rem;
  padding-top: 5rem;
}

.wapper-cover {
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

.member-cover {
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.desk {
  display: block;
}

.mob {
  display: none;
}

.modal-close {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 1rem;
  z-index: 1;
  font-size: 1.5rem;
  line-height: normal;
  padding: 15px;
  color: #81d4fa;
  cursor: pointer;
}

html[dir="ltr"] .modal-close {
  right: 1rem;
}


html[dir="ltr"] .modal-close.external {
  transform: translate(50%, -50%);
  right: 0;
  left: unset;
}

.modal-close.external {
  transform: translate(-50%, -50%);
  left: 0;
}

.property-title {
  display: inline-block;
  padding-inline-end: 5px;
  font-weight: bold;
  color: var(--darkBlue);
}

.wapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.swiper-ativity-times .swiper-slide {
  height: auto
}

.side-title {
  display: flex;
  flex-direction: column;
  padding-inline-end: 3rem;
  border-color: var(--pasterlGrayDark);
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
}

.title-rich {
  font-family: "OpenSans-Regular", "HelveticaNeue-Bold";
  color: var(--darkBlue);
  font-size: 3.25rem;
  line-height: normal;
}

.title-rich p {
  margin: 0;
}

.avatar {
  position: relative;
  border-color: var(--darkBlue);
  background-color: white;
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows {
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  bottom: 1rem;
  background-size: contain;
}

.menu-item {
  position: relative;
  white-space: pre;
}

.menu-mob {
  transform: translateX(-100%);
  transition: 0.5s;
  animation-duration: 0.5s;
}

.menu-mob.show {
  animation-name: slideSide;
  animation-fill-mode: forwards;
}

.menu-mob.close {
  animation-name: slideClose;
  animation-fill-mode: forwards;
}

.card-border {
  display: flex;
  align-items: center;
  justify-content: center;

}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0px;
  background-color: var(--white);
  border-radius: 0.3125rem;
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 10px 0px;
  min-width: 80%;
  outline: none;
}

@keyframes slideSide {
  from {
    transform: translateX(-100%)
  }

  to {
    transform: translateX(0)
  }
}

@keyframes slideClose {
  from {
    transform: translateX(0)
  }

  to {
    transform: translateX(-100%);
  }
}


.slide-show {
  transform: translateX(0)
}

.menu-item:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
}


.circle {
  height: 10px;
  width: 10px;
  border: 1px solid #474747;
  border-radius: 5px;
  margin: 0px -5px;
  background-color: var(--white);
  box-sizing: border-box;
}

.img-bullet {
  transform: translateX(50%);
  background-color: white;
}

.route-plan-bar {
  /* display: flex; */
  align-items: center;
  /* height: 8rem; */
  background-color: var(--white);
  width: 100%;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.25);
  /* position: absolute; */
  z-index: 5;
}

.img-card-info {
  width: 100%;
  max-width: 15rem;
}

.is-open {
  color: var(--success);
}

.is-close {
  color: var(--red);
}

.station-options-flex {
  display: grid;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, min(100%/4, max(11rem, 100%/5.5))));
}

.station-options-grid {
  /* justify-content: space-between; */
  row-gap: 3rem;
  column-gap: 3rem;
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
}

/* .station-options::after {
  content: "";
  flex: 1 1 10rem;
    max-width: 10rem;
    text-align: center;
    min-width: 13%;
} */



.wapper-accordion {
  position: relative;
  display: flex;
  flex: 1 1 37rem;
  max-width: 38rem;
  margin: 0;

}

.accordion {
  background-color: var(--white);
  text-align: center;
  box-shadow: var(--shadowColor);
  border-radius: 1rem;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.overflow-hidden {
  overflow: unset;
}

.float-icon {
  z-index: 1;
  padding: 0.5rem;
  box-shadow: var(--shadowColor);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  margin: auto;
  top: 0;
  left: 50%;
  position: absolute;
  width: 3rem;
  height: 3rem;
}

.float-icon img {
  width: 100%
}

.description-extend {
  width: 100%;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  height: 0;
  transition: 0.5s;
  background: white;

}

.extend {
  display: flex;
  flex-direction: column;
  height: 20rem;
  /* border-top: 12px solid var(--darkBlue); */
  /* box-shadow: var(--shadowColor); */
  border-radius: 0 0 1rem 1rem;
  border-bottom: 0.2rem solid white;

}

.description-extend-content {
  padding: 2rem;
  text-align: start;
  overflow: auto;
  height: 100%;
}

.dot:hover {
  border: 0.2vw solid var(--koromiko);
}

.questionAndAnswer .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.questionAndAnswer .answer.active {
  max-height: 500px;

}

.card.contact {
  min-width: 8rem;
  width: calc(25% - 1.5rem);
}

/* @media screen and (max-width: 980px) {
  .card.contact {
    width: calc(50% - 1.5rem);
  }
  

} */

.articles-media .article-img{
  /* aspect-ratio: 1/0.55; */
  height: 17.357rem;
}

@media screen and (max-width: 768px) {
  div.top-banner-header::after {
    background-image: linear-gradient(0deg, transparent 0%, #000000 100%);
  }
  .card.contact {
    width: calc(50% - 1rem);
  }
  .swiper-ativity-times .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-ativity-times .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }

  .station-options-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 3rem;
    column-gap: 1rem;

  }

  .step {
    align-items: center
  }

  .desk {
    display: none;
  }

  .mob {
    display: block;
  }

  .wapper {
    flex-direction: column;
    justify-content: space-between;
  }

  .side-title {
    border-inline-end-width: 0;
    border-bottom-width: 1px;
    padding-left: 0;
  }


  .avatar {
    width: 12rem;
    height: 12rem;
  }

  .arrows {
    width: 1.5rem;
    height: 1.5rem;
  }

  .card-body {
    background-color: unset;
    border-radius: unset;
    box-shadow: unset;
    min-width: 100%;

  }

  .card-border {
    transform: unset;
  }
}

@media screen and (max-width: 480px) {

  .card.contact {
    width: calc(100%);
  }
  .avatar {
    width: 10rem;
    height: 10rem;
  }

  .arrows {
    width: 1rem;
    height: 1rem;
  }

  .mySwiper .swiper-button-next,
  .mySwiper .swiper-button-prev {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }

  .btn-standard {
    min-width: 3rem;
    margin: 0.2rem;
  }

  .modal-inner.smaller {
    gap: 1rem;
    height: 8rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .station-options-grid {
    justify-content: center;
  }
}

.happened-box {
  cursor: pointer;
  z-index: 999999;
}

.happened-box-inner {
  opacity: 0;
  transition: 0.5s;
}

.happened-box:hover .happened-box-inner {
  opacity: 1;
}

.boxss {
  background-color: #D8F3FE;
  transition: 1s;
  /* cursor: pointer; */
}



.lets-talks {
  color: #00458A;
  font-size: 1.68;
  font-family: 'OpenSans-Light';
  letter-spacing: 0.44px;
  line-height: 1.875rem;
}

.lets-note {
  color: #00458A;
  font-family: 'OpenSans-Bold';
  font-size: 1.68;
  letter-spacing: 0.75px;
  line-height: 1.68;
}

.lets-description {
  color: #00458A;
  font-family: 'OpenSans-Regular';
  font-size: 1.125;
  letter-spacing: 0.57px;
  line-height: 1.125;
}

.name-and-position {
  padding-block: 1rem;
  transition: 0.25s;
  color: #00458A;
  font-family: 'OpenSans-Regular';
  font-size: 1.125;
  letter-spacing: 0.57px;
  line-height: 1.125;
  text-align: center;
}

.top-box {
  background-color: #D8F3FE;
  padding: 1rem;
  flex-direction: column;
  display: flex;
  gap: 7.5px;
  opacity: 0;
  transition: 1s;
  transform: translateY(0);
  position: absolute;
  position: absolute;
  z-index: -1;


}

.boxss:hover .line-show-in-over {
  border: 2px solid #f9ba4d;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  top: 1rem;
  right: 1rem;
  position: absolute;
}

.boxss:hover .line-show-in-over::after {
  content: " ";
  position: absolute;
  width: 2px;
  height: 0;
  background-color: #f9ba4d;
  left: 50%;
  transform: translate(-50%, -100%);
}

.boxss:hover .line-show-in-over::after {

  animation: lineShow 0.5s forwards;

}

.top-box.show-box {
  /* opacity: 1;
transform: translateY(0); */
  animation: slidershow 1s forwards;
}

.top-box.hide {
  /* opacity: 1;
transform: translateY(0); */
  animation: sliderhidden 0.8s forwards;
}

@keyframes lineShow {
  0% {
    opacity: 0;
    height: 0;
  }

  25% {
    opacity: 0.5;
    height: 1rem;
  }

  100% {
    opacity: 1;
    height: 3.3rem;
  }
}

@keyframes slidershow {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  25% {
    transform: translateY(-50%);
    opacity: 0;

  }

  100% {
    transform: translateY(calc(-100% - 2rem));
    opacity: 1;
  }
}

@keyframes sliderhidden {
  0% {
    transform: translateY(calc(-100% - 2rem));
    opacity: 1;
  }

  75% {
    transform: translateY(-50%);
    opacity: 0;

  }

  100% {
    transform: translateY(0);
    opacity: 0;

    z-index: -1;

  }
}

[disabled] {
  opacity: 0.8;
}

.name-and-position.hide {
  opacity: 0;
}

/* .my-SwiperSlide{
  transition: 1s;
  transform-origin: 100% 100%;
} */

.worker-swiper .swiper-wrapper {
  align-items: end !important;
}

.swiperCenter .swiper-wrapper {
  justify-content: center;

}

#stationList {
  -webkit-mask-image: linear-gradient(to bottom, black calc(100% - 10px), transparent 100%);
  mask-image: linear-gradient(to bottom, black calc(100% - 10px), transparent 100%);
}

/* .my-SwiperSlide .boxss img{
  height: 250px !important;

}*/
/* .my-SwiperSlide:hover{
 

  transform: scaleY(1.5);
}
  */
.mainScroll:has(.modal-bottom) {
  overflow: hidden !important;
}

.modal-icon {
  position: absolute;
  top: 0;
  translate: 0 -50%;
  border-radius: 50%;
  background-color: white;
  padding: 1rem;
  width: 2rem;
  display: flex;
  height: 2rem;
  box-shadow: var(--shadowColor);
  justify-content: center;
  align-items: center;
}

.inner {
  display: flex;
  position: absolute;
  background-color: white;
  min-height: 20%;
  padding: 1rem;
  border-radius: 1rem;
  min-width: 17%;

}

.inner.desktop {
  opacity: 1;
  animation: grow 500ms forwards;
}

.inner.mob {
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 1rem 1rem 0 0;
  translate: 0 100%;
  animation: slideSidePop 500ms forwards;
}


.inner.desktop.close {
  animation: shrink 250ms forwards;
}

.inner.mob.close {
  animation: slideClosePop 250ms forwards;
}

@keyframes slideSidePop {
  from {
    translate: 0 100%;
    opacity: 0;

  }

  to {
    translate: 0;
    opacity: 1;


  }
}

@keyframes slideClosePop {
  from {
    translate: 0;
    opacity: 1;


  }

  to {
    translate: 0 100%;
    opacity: 0;


  }
}

.wrapper-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  flex: 1;
  align-items: center;
  padding-top: 1.5rem;

}

.content {
  max-width: 76rem;
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.btn-modal {
  text-align: center;
  border-radius: 50px;
  padding: 0.8rem 1rem;
  border: 0;
  width: 12rem;

}

.icon-app {
  width: 2.81rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: black
}

.icon-app>img {
  width: 100%;
}



.custom-list {

  overflow: auto;
  max-height: 249px;
}

.custom-list .list-key {
  cursor: pointer;
  transition: 0.3s;
  padding: 0.5rem;
  text-align: center;
  border-bottom: 1px solid #D7D7D7;
}

.custom-list .list-key:hover {
  background-color: #f5f5f5;
}

.custom-list .list-key.active {
  background-color: #f5f5f5;
}

.custom-list .list-key:last-child {
  border: 0;
}

.text-balance{
  text-wrap: balance;
}