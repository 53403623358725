:root {
  letter-spacing: 0.06rem;
  --fontSize: 14.8px;
  --blue: #0b79c4;
  --cyan: #0dcaf0;
  --indigo: #050505;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --green: #198754;
  --teal: #20c997;
  --white: #fff;
  --grayDark: #343a40;
  --success: #198754;
  --light: #f8f9fa;
  --dark: #050505;
  --gray: #868585;
  --ghostWhite: #f9f9f9;
  --pastelGray: #cdcdcd;
  --pasterlGrayDark: #757575;
  --platinum: #e2e2e2;
  --darkCyan: #00acec;
  --darkBlue: #00458a;
  --darkBlueHalf: rgba(0, 69, 138, 0.5);
  --whiteSmoke: #f3f3f3;
  --koromiko: #f9ba4d;
  --bittersweet: #f06f65;
  --nero: #282828;
  --pattensBlue: #d8f2fc;
  --grayMixed: #504949e6;
  --dimGray: #707070;
  --black: #000000;
  --cyanBold: #009dd6;
  --lighterGray: #f2f2f2;
  --whiteTransparent: rgba(255, 255, 255, 0.8);
  --lightGray: rgba(216, 216, 216, 0.1);
  --modal: #000000cc;
  --modalLight: #0000006;
  --shadowColor: 0 0 10px #00000026;
  --swiper-theme-color: var(var(--white));
}

/* *{
  box-sizing: border-box;
} */
html {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

p {
  margin-block: 0;
}

img {
  max-width: 100%;
}

body {
  height: 100vh !important;
  width: 100%;
  line-height: 1.3rem;
  margin: 0;
  font-family: "NotoSansHebrew-Regular, FbReforma-Regular", "HelveticaNeue",
    "-apple-system", "system-ui", "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "sans-serif",
    "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  writing-mode: inherit;
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  overflow: hidden !important;
}

#glassix-container {
  display: none;
}

input[type="text"],
input[type="email"] {
  outline: 1px transparent;
  border: none;
}

[dir="rtl"] table {
  border: hidden;
}

[dir="ltr"] table {
  border: hidden;
}

table td {
  border-left: 0;
  border-right: 0;
  font-family: "FbReforma-Light";
  font-size: 1.4rem;
}

table td a:visited {
  color: rgba(117, 117, 117, 1);
}

table td a:active {
  color: rgba(117, 117, 117, 1);
}

table tr:first-child td {
  background-color: #f9f9f9;
  font-family: "FbReforma-Medium";
  font-size: 1.4rem;
  color: #00458a;
  line-height: 3.4rem;
}

table th {
  background-color: #f9f9f9;
  font-family: "FbReforma-Medium";
  font-size: 1.4rem;
  color: #00458a;
  line-height: 3.4rem;
}

ul {
  list-style-type: none;
  list-style-image: url("../src/Assets/Icons/bullet.svg");
  padding-inline-start: 20px;
  margin-block-start: 10px;
  margin-block-end: 0;
}

li {
  padding-inline-start: 0 !important;
}

ul li {
  /* padding-bottom: 1rem; */
}

a {
  color: #1465a9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #09487a;
}

.sitemaplink {
  visibility: hidden;
}

.sitemaplink:hover {
  visibility: hidden;
}

.mobileLink {
  text-decoration: underline !important;
}

a:active {
}

.submenu {
}

.dropdown {
  display: none;
}

.container {
  width: 80%;
  /* padding: 0 2rem; */
  padding: 0 2rem;
  max-width: 76rem;
  margin: auto;
}

.over-hidden {
  overflow: hidden;
}

.centering {
  justify-content: center;
  align-items: center;
}

audio {
  width: 100%;
  height: 3rem;
}

audio::-webkit-media-controls-panel {
  background-color: white;
  border: none;
}

audio::-webkit-media-controls-timeline {
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

audio::-webkit-media-controls-enclosure {
  overflow: hidden;
}

audio::-webkit-media-controls-volume-slider {
  display: none;
}

audio::-webkit-media-controls-mute-button {
  display: none;
}

.closedStationInfo:hover {
  text-decoration: underline;
}

.flex {
  flex: 1;
  display: flex;
}

.column {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.row-between {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.row-around {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}

.wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.col {
  display: flex;
  flex: 1;
}

.col.biger {
  flex: 3;
}

.bold {
  font-weight: bold;
}

.gap {
  gap: 5rem;
}

.underline {
  font-family: "FbReforma-Medium", "HelveticaNeue-Bold";
  text-align: center;
}

.underline::after {
  content: "";
  display: block;
  color: var(--darkBlue);
  height: 0.15rem;
  width: 2rem;
  background-color: var(--koromiko);
  margin-top: 0.4rem;
}

.mySwiper {
  position: relative;
  width: 100%;
}

.arrows-main-test {
  width: 3rem;
  /* background-image: url("../../../static/media/directorateArrow.05cb0913761513c508db1242a89d33ab.svg"); */
  height: 3rem;
  z-index: 2;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
}

.arrows-main.prev {
  right: 0;
  transform: translateY(-50%);
}

.arrows-main.next {
  left: 0;
  transform: translateY(-50%) rotateZ(180deg);
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  border-radius: 50%;
  background-color: white;
  width: 3.125rem;
  height: 3.125rem;
  box-shadow: var(--shadowColor);
  padding: 0px;
  font-size: 1.5rem;
  text-align: center;
}

.mySwiper .swiper-button-next::after,
.mySwiper .swiper-button-prev::after {
  font-size: 1.5rem;
}

.cover-color-blur {
  background-image: linear-gradient(to right, transparent, #00000080);
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3.125rem;
  height: 10vh;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.customScrollbar {
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

.customScrollbar::-webkit-scrollbar {
  width: 0.6rem;
  height: 2.3rem;
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
}

.customScrollbarBlue::-webkit-scrollbar-thumb {
  background-color: #00458a;
}

.filters::-webkit-scrollbar {
  height: 0;
}

.modalScrollX::-webkit-scrollbar {
  /* Remove scrollbar space */
  width: 0;
  height: 0;
}

/* ----------------------- Wheel picker overrides ----------------------- */

/* new wheel picker */

.wheelPicker::-webkit-scrollbar {
  /* Remove scrollbar space */
  width: 0;
}

/* end new wheel picker */

/* set date picker next arrow on left side on RTL language */
[dir="rtl"] .react-datepicker__navigation--next {
  left: 0 !important;
  transform: rotate(-180deg);
  right: auto !important;
}

/* set date picker previous arrow on right side on RTL language */
[dir="rtl"] .react-datepicker__navigation--previous {
  right: 0 !important;
  transform: rotate(-180deg);
}

.react-datepicker__day--selected {
  border-radius: 50% !important;
  background-color: rgba(10, 68, 142, 1);
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

.react-datepicker {
  font-size: 1rem !important;
  border: none !important;
}

.react-datepicker.react-datepicker--time-only {
  font-size: 1rem !important;
  border: 1px solid #aeaeae !important;
  /* border: block !important; */
}

.react-datepicker__header {
  background-color: white !important;
  border-radius: 0 !important;
  border-bottom: none !important;
  padding-top: 0.8em !important;
}

.react-datepicker__month {
  /* margin: 0.4em 1em !important; */
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 3.5em !important;
  line-height: 3.5em !important;
  margin: 0.166em !important;
}

.react-datepicker__day-names {
  font-family: "FbReforma-Medium", "HelveticaNeue-Bold";
}

.react-datepicker__day-names div {
  /* color: #009dd6; */
  color: rgba(10, 68, 142, 1);
}

.react-datepicker__current-month {
  font-size: 1.5em !important;
  padding-bottom: 1.5rem;
}

.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
  filter: brightness(0.5);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50% !important;
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .blurred-container {
    filter: blur(0.5rem) brightness(0.45);
  }

  .blurred-container-mobile {
    filter: brightness(0.4);
  }

  .coverWrapper {
    background-color: #000000;
  }
}

@-moz-document url-prefix() {
  .switchIcon-firefox {
    transform: scale(0.5);
  }
}

/* Search Results */
/* hide scrollbar in filters */
#filters::-webkit-scrollbar {
  display: none;
}

@keyframes moveUpDown {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(30px);
  }
}

@keyframes favoriteTravelGrow {
  from {
    transform: scale(0.3);
  }

  to {
    transform: scale(1);
  }
}

@keyframes routePlanTitleGrow {
  from {
    transform: scale(0.2);
  }

  to {
    transform: scale(1.8);
  }
}

@keyframes tickerMove {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-30rem);
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes closeLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes moveRight {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes shadowOver {
  from {
    transform: translateY(0px);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.11);
  }

  to {
    box-shadow: rgb(0 0 0 / 11%) 0px 10px 35px 2px;
  }
}

:root {
  --font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  --gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  font-size: var(--fontSize);
}

@font-face {
  font-family: "FbReforma-Regular";
  src: url("./Assets/Fonts/FbReforma/FbReforma-Regular.eot");
  src: url("./Assets/Fonts/FbReforma/FbReforma-Regular.eot#iefix")
      format("embedded-opentype"),
    url("./Assets/Fonts/FbReforma/FbReforma-Regular.woff") format("woff"),
    url("./Assets/Fonts/FbReforma/FbReforma-Regular.ttf") format("truetype"),
    url("./Assets/Fonts/FbReforma/FbReforma-Regular.svg#FbReforma")
      format("svg");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39,
    U+003A-0040;
}

@font-face {
  font-family: "FbReforma-Medium";
  src: url("./Assets/Fonts/FbReforma/FbReforma-Medium.eot");
  src: url("./Assets/Fonts/FbReforma/FbReforma-Medium.eot#iefix")
      format("embedded-opentype"),
    url("./Assets/Fonts/FbReforma/FbReforma-Medium.woff") format("woff"),
    url("./Assets/Fonts/FbReforma/FbReforma-Medium.ttf") format("truetype"),
    url("./Assets/Fonts/FbReforma/FbReforma-Medium.svg#FbReforma") format("svg");
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39,
    U+003A-0040;
}

@font-face {
  font-family: "FbReforma-Bold";
  src: url("./Assets/Fonts/FbReforma/FbReforma-Bold.otf");

  font-weight: 600;
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39,
    U+003A-0040;
}

@font-face {
  font-family: "FbReforma-Light";
  src: url("./Assets/Fonts/FbReforma/FbReforma-Light.eot");
  src: url("./Assets/Fonts/FbReforma/FbReforma-Light.eot#iefix")
      format("embedded-opentype"),
    url("./Assets/Fonts/FbReforma/FbReforma-Light.woff") format("woff"),
    url("./Assets/Fonts/FbReforma/FbReforma-Light.ttf") format("truetype"),
    url("./Assets/Fonts/FbReforma/FbReforma-Light.svg#FbReforma") format("svg");
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39,
    U+003A-0040;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./Assets/Fonts/HelveticaNeue/nhr.eot");
  src: url("./Assets/Fonts/HelveticaNeue/nhr.eot#iefix")
      format("embedded-opentype"),
    url("./Assets/Fonts/HelveticaNeue/nhr.woff2") format("woff2"),
    url("./Assets/Fonts/HelveticaNeue/nhr.woff") format("woff"),
    url("./Assets/Fonts/HelveticaNeue/nhr.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("./Assets/Fonts/HelveticaNeue/nhm.eot");
  src: url("./Assets/Fonts/HelveticaNeue/nhm.eot#iefix")
      format("embedded-opentype"),
    url("./Assets/Fonts/HelveticaNeue/nhm.woff2") format("woff2"),
    url("./Assets/Fonts/HelveticaNeue/nhm.woff") format("woff"),
    url("./Assets/Fonts/HelveticaNeue/nhm.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansHebrew-Light";
  src: url("./Assets/Fonts/NotoSansHebrew/NotoSansHebrew-Light.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "NotoSansHebrew-Regular";
  src: url("./Assets/Fonts/NotoSansHebrew/NotoSansHebrew-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansHebrew-Medium";
  src: url("./Assets/Fonts/NotoSansHebrew/NotoSansHebrew-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("./Assets/Fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("./Assets/Fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./Assets/Fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("/src/Assets/Fonts/Rubik/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.printing {
  display: none;
}

.max-width {
  padding: 0 1rem;
  max-width: 76rem;
  margin: 0 auto;
  width: 100%;
}

.printing .route-station-name {
  font-size: 1.5rem;
}

.printing .route-time {
  font-size: 1.5rem;
}

.top-banner-header {
  position: relative;
  isolation: isolate;
}

.top-banner-header::after {
  content: "";
  position: absolute;
  background-image: linear-gradient(90deg, transparent 0%, #00458a 100%);
  opacity: 0.6;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.top-banner {
  padding: 2rem;
}

.top-banner .logo {
  width: 10rem;
  margin-bottom: 1rem;
}

.top-banner .logo img {
  width: 100%;
}

.header-print {
  box-shadow: var(--shadowColor);
  border-radius: 1rem;
  padding: 1rem;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-print img {
  height: 100%;
}

.data-time-print {
  font-family: "FbReforma-Medium", "HelveticaNeue";
  color: var(--darkBlue);
  font-size: 1.5rem;
  display: inline-block;
}

.from-to-print {
  font-size: 1.2rem;
  line-height: calc(1rem + 2px);
  font-family: "FbReforma-Regular", "HelveticaNeue";
  font-weight: bold;
  color: rgb(0 69 138);
  align-items: center;
  display: flex;
}

/* .printing.show-print + .footer{display: none;} */
.route-info-text {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 1rem;
  font-family: "FbReforma-Regular", "HelveticaNeue";
  color: #767676;
}

.click {
  cursor: pointer;
}

.train-card {
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
}

.print-mode-show {
  display: none;
}

/* .mode-print .body{display: none !important;} */
@media print {
  .print-mode-show {
    display: unset;
  }

  .print-mode-show .map-print {
    width: 100vw;
    height: 100vh;
  }
  #mapContainer {
    zoom: unset !important;
    width: 95% !important;
  }
  #Map {
    width: 100% !important;
    zoom: unset !important;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
  }

  .mode-print #root :not(.print-mode-show, .print-mode-show *) {
    display: none !important;
  }

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      font-size: 1rem !important;
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
    .route-details-change {
      font-size: 1rem !important;
    }
    .route-details {
      max-height: unset !important;
      overflow-y: hidden !important;
    }
    .print-control{
      display:block !important;
    }
    /* .data-time-print {
      font-family: "FbReforma-Medium", "HelveticaNeue" !important;
      color: var(--darkBlue) !important;
      font-size: 1.5rem !important;
      display: inline-block !important;
    }
    
    .from-to-print {
      font-size: 1.2rem !important;
      line-height: calc(1rem + 2px) !important;
      font-family: "FbReforma-Regular", "HelveticaNeue" !important;
      font-weight: bold !important;
      color: rgb(0 69 138) !important;
      align-items: center !important;
      display: flex !important;
    } */
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
      page-break-after: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }

  .mode-print {
    height: unset !important;
    overflow: auto !important;
  }

  .mode-print .route-details {
    max-height: unset !important;
  }

  .mode-print .route-details {
    overflow: auto !important;
  }



  

  .mode-print .printing.show-print {
    display: unset;
    left: 0;
    top: 0;
    background-color: white;
    width: 100%;
    height: 100vh;
    z-index: 99999999;
  }

  .mode-print .body-list-trains {
    padding-top: 0 !important;
  }

  .printing .list-trains {
    display: flex !important;
  }

  .printing .trains-cards {
    overflow: unset !important;
  }

  .printing.show-print .route-info-text {
    font-size: 1.3rem;
  }

  .mode-print .change {
    padding-bottom: 10px;
    color: rgb(0, 69, 138);
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }

  .img-bullet {
    width: 1.5rem;
  }

  .route-details {
    font-size: 3.125rem;
  }
}

.shareOption:hover {
  text-decoration: underline;
}

.cover-blur::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(0.5rem);
  mask: linear-gradient(90deg, transparent, black 60%);
  transition: 1s;
}

[dir="ltr"] .cover-blur::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(0.5rem);
  mask: linear-gradient(-90deg, transparent, black 60%);
  transition: 1s;
}

.banner-search-job {
  box-sizing: border-box;
}

.page-item.active {
  background: rgb(0, 69, 138);
  padding: 8px;
  color: white !important;
  border-radius: 0.625rem;
  cursor: default !important;
  padding-inline-start: 8px !important;
}

@keyframes draw {
  0% {
    fill: #ffffff;
    stroke: #0066ff;
    stroke-width: 0.2vmin;
    opacity: 1;
  }

  25% {
    fill: #ffffff;
    stroke: #00a6ff;
    stroke-width: 0.3vmin;
    opacity: 0.9;
  }

  50% {
    fill: #ffffff;
    stroke: #00f0ff;
    stroke-width: 0.4vmin;
    opacity: 0.8;
  }

  75% {
    fill: #ffffff;
    stroke: #00a6ff;
    stroke-width: 0.3vmin;
    opacity: 0.9;
  }

  100% {
    fill: #ffffff;
    stroke: #0066ff;
    stroke-width: 0.2vmin;
    opacity: 1;
  }
}

.currentlocation {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 0.1;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-opacity: 1;
  animation-name: draw;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#tooltip .swiper-button-next {
  top: 45%;
}

#tooltip .swiper-button-prev {
  top: 45%;
}

#defaultGalleryMobile .swiper-pagination {
  bottom: 25%;
}

#defaultGalleryMobile .swiper-pagination-bullet {
  background: transparent !important;
  border: 1px solid white;
  opacity: 1;
}

#defaultGalleryMobile .swiper-pagination-bullet-active {
  background-color: white !important;
  border: 1px solid white;
  opacity: 1;
}

#defaultGallery .swiper-pagination {
  bottom: 20%;
}

#defaultGallery .swiper-pagination-bullet {
  background: transparent !important;
  border: 1px solid white;
  opacity: 1;
}

#defaultGallery .swiper-pagination-bullet-active {
  background-color: white !important;
  border: 1px solid white;
  opacity: 1;
}

#topContainer .swiper-pagination {
  bottom: 20%;
  width: 15%;
  min-width: 7rem;
  right: 9%;
  left: 8.3%;
}

#topContainer .swiper-pagination-bullet {
  background: transparent !important;
  border: 1px solid white;
  opacity: 1;
}

#topContainer .swiper-pagination-bullet-active {
  background-color: white !important;
  border: 1px solid white;
  opacity: 1;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  display: flex;
  justify-content: space-evenly;
  padding-top: 1.2rem;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  padding-right: 0.6rem;
}

.react-datepicker__month-dropdown {
  background: white !important;
  height: 250%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 1px 0px,
    rgb(0 0 0 / 19%) 0px 0px 15px 0px;
  border-radius: 0.625rem !important;
  top: 3rem !important;
  font-family: "NotoSansHebrew-Regular";
  font-size: 1.1rem;
}

.react-datepicker__year-dropdown {
  background: white !important;
  height: 250%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 1px 0px,
    rgb(0 0 0 / 19%) 0px 0px 15px 0px;
  border-radius: 0.625rem !important;
  top: 3rem !important;
  font-family: "NotoSansHebrew-Regular";
  font-size: 1.3rem;
}

/*
.react-datepicker__current-month{
  cursor: default;
}*/

.react-datepicker__navigation--years {
  position: absolute !important;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  color: grey;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:last-child::after {
  content: "\25BC";
  color: grey;
}

.auctionsMobileTabs::-webkit-scrollbar {
  display: none !important;
}

.outerstationsdivSafariWeb {
  position: fixed;
  /*web*/
  min-width: 22rem !important;
  /*web*/
  min-height: 1.5rem !important;
  /*web*/
  justify-content: start !important;
}

.forigenSafariWeb {
  direction: rtl;
}

.outerstationsdivSafariMobile {
  position: fixed;
  /*mobile*/
  width: 0% !important;
  /*mobile*/
  min-height: 0.5rem !important;
  /*mobile*/
  right: 7rem !important;
  /*mobile*/
}

.smallstations_heSafariMobile {
  font-size: 25% !important;
  /*mobile*/
}

.smallstations_enSafariMobile {
  font-size: 25% !important;
  /*mobile*/
}

.smallstations_arSafariMobile {
  font-size: 25% !important;
  /*mobile*/
}

.smallstations_ruSafariMobile {
  font-size: 25% !important;
  /*mobile*/
}

.smallheader_heSafariMobile {
  font-size: 50% !important;
  /*mobile*/
}

.smallheader_enSafariMobile {
  font-size: 50% !important;
  /*mobile*/
}

.smallheader_arSafariMobile {
  font-size: 50% !important;
  /*mobile*/
}

.smallheader_ruSafariMobile {
  font-size: 50% !important;
  /*mobile*/
}

.all-updates {
  text-decoration: none;
}

.all-updates:hover {
  text-decoration: underline;
}

.text-wrapper {
  text-wrap: balance;
}

.scroll-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE */
  height: 100vh; /* Full viewport height */
  
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.snap-item {
  scroll-snap-align: start;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Avoid showing content before fading */
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s; /* Fade-in effect */
}

.snap-item.visible {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Show content */
  transition: opacity 0.5s ease-out, visibility 0s linear 0s; /* Enable fade-in effect */
}
